var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isUpdatedSwiper)?_c('section',{staticClass:"slider",class:[
    `slider-${_vm.id}${_vm._uid}`,
    { 'slider_is-full': _vm.isFull },
    { 'slider_multiple': _vm.isMultiple },
    { 'slider_dark': _vm.theme == 'dark' },
    { 'slider_secondary-mode': _vm.secondaryMode },
  ]},[(_vm.isDesktopDisabled && !_vm.isDesktop && !_vm.disabled)?_c('swiper',{ref:"Swiper",attrs:{"options":_vm.swiperOptions}},[_vm._t("default")],2):_c('div',{staticClass:"disabled-slider-content"},[_vm._t("default")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisibleControls),expression:"isVisibleControls"}],staticClass:"slider__controls",class:{ 'g-hidden': _vm.isFull}},[_c('div',{staticClass:"slider__prev",class:[`slider-${_vm.id}${_vm._uid}__prev`],attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('svg',{attrs:{"width":"10","height":"15","viewBox":"0 0 10 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.5 1L2 7.5L8.5 14","stroke":"white","stroke-width":"2"}})])]),_c('div',{staticClass:"slider__count",class:`slider-${_vm.id}${_vm._uid}__count`,attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"slider__next",class:[`slider-${_vm.id}${_vm._uid}__next`],attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('svg',{attrs:{"width":"9","height":"15","viewBox":"0 0 9 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L7.5 7.5L1 14","stroke":"white","stroke-width":"2"}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.items > 1),expression:"items > 1"}],staticClass:"slider__bullets slider-pagination",class:[`slider-${_vm.id}${_vm._uid}__bullets slider-pagination--theme-${_vm.paginationTheme}`, { 'g-hidden': _vm.isFull }]})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }