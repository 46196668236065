<template>
  <div class="business-program-item" @click="onClick">
    <div class="business-program-item__inner">
      <div class="business-program-item__name ff-title title-lg title-lg--mob-lg" v-html="program.name" />
      <div v-if="program?.tags?.length" class="business-program-item__tags">
        <BusinessProgramTags :tags="program?.tags" />
      </div>
      <div v-if="program.time || program.address" class="business-program-item__footer">
        <div v-if="program.time" class="business-program-item__time business-program-item__footer-item">
          <InlineSvg class="business-program-item__footer-icon" src="program/time" />
          <span>
            {{ program.time }}
          </span>
        </div>
        <div v-if="program.zone" class="business-program-item__address business-program-item__footer-item">
          <InlineSvg class="business-program-item__footer-icon" src="program/marker" />
          <span>
            {{ program.zone }}
          </span>
        </div>
      </div>
      <SpinnerLoading mode="block" :isLoadingBlock="isLoading" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    program: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    ...mapGetters('program', ['IS_LOADING_DETAIL_PROGRAM']),

    isLoading() {
      return this.IS_LOADING_DETAIL_PROGRAM === this.program.id
    }
  },

  methods: {
    onClick() {
      if (this.isLoading) {
        return
      }

      this.$emit('click', this.program)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-program-item';

#{$b} {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @include hover {
    #{$b}__inner {
      @include border-inset(2px, $bg-brand);
    }
  }

  // .business-program-item__inner
  &__inner {
    position: relative;
    padding: 32px;
    background-color: $white-true;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.3s ease;

    @include tablet {
      padding: 24px;
    }
  }

  // .business-program-item__name
  &__name {
    &:not(:last-child) {
      margin-bottom: 24px;

      @include tablet {
        margin-bottom: 16px;
      }
    }
  }

  // .business-program-item__tags
  &__tags {
    &:not(:last-child) {
      margin-bottom: 40px;

      @include tablet {
        margin-bottom: 18px;
      }
    }
  }

  // .business-program-item__footer
  &__footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 18px 40px;
    padding-right: 110px;

    @include low-desktop {
      gap: 18px 24px;
    }

    @include tablet {
      padding-right: 0;
      padding-bottom: 50px;
    }

    @include mobile {
      display: block;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 91px;
      height: 12px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="91" height="12" viewBox="0 0 91 12" fill="none"><path d="M90.5303 6.53033C90.8232 6.23744 90.8232 5.76256 90.5303 5.46967L85.7574 0.696699C85.4645 0.403806 84.9896 0.403806 84.6967 0.696699C84.4038 0.989593 84.4038 1.46447 84.6967 1.75736L88.9393 6L84.6967 10.2426C84.4038 10.5355 84.4038 11.0104 84.6967 11.3033C84.9896 11.5962 85.4645 11.5962 85.7574 11.3033L90.5303 6.53033ZM0 6.75H90V5.25H0V6.75Z" fill="%23003DFF"/></svg>');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;

      @include tablet {
        left: 0;
        bottom: 0;
        top: unset;
        right: unset;
        transform: none;
      }
    }

    // .business-program-item__footer-item
    &-item {
      display: inline-flex;
      gap: 8px;
      opacity: 0.5;

      @include mobile {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

    // .business-program-item__footer-icon
    &-icon {
      @include boxsize(20px);
    }
  }
}
</style>
