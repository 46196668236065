

export default {
  namespaced: true,

  state: {
    isLoading: false
  },

  getters: {
    IS_LOADING(state) {
      return state.isLoading
    }
  },

  mutations: {
    SET_LOADING(state, loading) {
      state.isLoading = loading
    },
  }
}
