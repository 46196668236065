<template>
  <div class="video-block">
    <div class="video-block__inner">
      <div v-for="item in items" :key="`video-block__item-${item.id}`" class="video-block__item">
        <VideoCard :card="item" class="video-block__card" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.video-block';

#{$b} {
  // .video-block__inner
  &__inner {
    @include gridcols(2, 16px);

    @include tablet {
      @include gridcols(1, 16px);
    }
  }
}
</style>

