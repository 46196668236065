export default {
  namespaced: true,
  state: {
    isOpenedMenu: false,
  },

  getters: {
    IS_OPENED_MENU(state) {
      return state.isOpenedMenu
    },
  },

  mutations: {
    SET_IS_OPENED_MENU(state, isOpened) {
      state.isOpenedMenu = isOpened
    },
  },
}
