
import cloneDeep from 'lodash-es/cloneDeep'

const DEFAULT_MODAL = {
  name: '',
  props: {}
}

export default {
  namespaced: true,

  state: {
    modal: cloneDeep(DEFAULT_MODAL)
  },

  getters: {
    MODAL(state) {
      return state.modal
    }
  },

  mutations: {
    SET_MODAL(state, modal) {
      state.modal = modal
    },
  },

  actions: {
    CLOSE_MODAL({ commit }) {
      commit('SET_MODAL', DEFAULT_MODAL)
    },
    OPEN_MODAL({ commit }, modal) {
      commit('SET_MODAL', modal)
    }
  }
}
