<template>
  <div
    v-if="isVisible"
    class="business-program-modal"
    :class="{ 'business-program-modal--is-visible': isAnimateVisible }"
    @mousedown.self="close"
  >
    <div class="business-program-modal__inner">
      <div class="business-program-modal__close" @click="close">
        <InlineSvg class="business-program-modal__close-icon" src="u-close" />
      </div>
      <div class="business-program-modal__header">
        <BusinessProgramTags v-if="program?.tags?.length" class="business-program-modal__tags" :tags="program.tags" />
        <div class="business-program-modal__title title-lg title-lg--mob-lg ff-title" v-html="program.name" />
        <div v-if="program.date || program.time || program.zone" class="business-program-modal__line">
          <div v-if="program.date" class="business-program-modal__line-item">
            <InlineSvg class="business-program-modal__line-icon" src="program/calendar" />
            {{ program.date }}
          </div>
          <div v-if="program.time" class="business-program-modal__line-item">
            <InlineSvg class="business-program-modal__line-icon" src="program/time" />
            {{ program.time }}
          </div>
          <div v-if="program.zone" class="business-program-modal__line-item">
            <InlineSvg class="business-program-modal__line-icon" src="program/marker" />
            {{ program.zone }}
          </div>
        </div>
        <div v-if="program.support" class="business-program-modal__partners">
          <div class="business-program-modal__partners-caption business-program-modal__partners-item">
            {{ getPartnerCaption }}
          </div>
          <div class="business-program-modal__partners-name business-program-modal__partners-item">
            {{ program.support }}
          </div>
        </div>
      </div>
      <div v-if="tabContentExists" class="business-program-modal__body">
        <div class="business-program-modal__tabs">
          <Tabs theme="primary">
            <Tab v-if="program.description" selected :title="getTabTitle.announcement">
              <template #content>
                <div v-html="program.description" class="u-typography business-program-modal__tabs-description" />
              </template>
            </Tab>
            <Tab v-if="program.roles?.length" :selected="!program.description" :title="getTabTitle.persons">
              <template #content>
                <div class="business-program-modal__persons">
                  <div
                    v-for="(persons, index) in program.roles"
                    :key="`business-program-modal__persons-block-${index}`"
                    class="business-program-modal__persons-block"
                  >
                    <div class="business-program-modal__persons-title title-lg title-lg--mob-lg ff-title">
                      {{ persons.name }}
                    </div>
                    <div class="business-program-modal__persons-items">
                      <BusinessProgramPerson
                        v-for="(person, i) in persons.participants"
                        :key="`business-program-modal__persons-item-${index}-${i}`"
                        class="business-program-modal__persons-item"
                        :person="person"
                        :lang="lang"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </Tab>
            <Tab v-if="program.broadcast" :selected="!program.description && !program.roles?.length" :title="getTabTitle.video">
              <template #content>
                <iframe class="business-program-modal__iframe" :src="program.broadcast" frameborder="0" border="0" allowfullscreen webkitallowfullscreen mozallowfullscreen scrolling="no"></iframe>
              </template>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    program: {
      type: Object,
      default: () => ({})
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: 'ru',
    }
  },

  data() {
    return {
      partnerCaptionLang: {
        'ru': 'При поддержке:',
        'en': 'In partnership with'
      },
      tabTitleLang: {
        'ru': {
          announcement: 'Анонс',
          persons: 'Участники',
          video: 'Трансляция',
        },
        'en': {
          announcement: 'Announcement',
          persons: 'Participants',
          video: 'Broadcasting',
        },
      },
      isAnimateVisible: false,
    }
  },

  computed: {
    getPartnerCaption() {
      return this.partnerCaptionLang[this.lang]
    },

    getTabTitle() {
      return this.tabTitleLang[this.lang]
    },

    tabContentExists() {
      return this.program.description || this.program.roles?.length || this.program.broadcast
    }
  },

  methods: {
    close() {
      this.isAnimateVisible = false
      setTimeout(() => {
        this.$emit('close')
      }, 100);
    },

    setBodyScrollState(isCanScrollBody) {
      const delta = window.innerWidth - document.documentElement.clientWidth

      document.body.style.overflow = isCanScrollBody ? '' : 'hidden'
      document.body.style.paddingRight = isCanScrollBody ? '' : `${delta}px`
    },
  },

  watch: {
    isVisible(state) {
      this.setBodyScrollState(!state)
      setTimeout(() => {
        this.isAnimateVisible = state
      }, 100);
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-program-modal';

#{$b} {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba($bg-brand, 0.60);
  backdrop-filter: blur(26px);
  padding: $container-gap;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
  z-index: 24;

  &:not(#{$b}--is-visible) {
    pointer-events: none;
    touch-action: none;
    background-color: transparent;

    #{$b}__inner {
      transform: translate(100%);
    }

    #{$b}__close {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  @include mobile-min {
    @include customScrollBar(6px, $bg-brand, rgba($bg-brand, 0.2));
  }

  @include tablet {
    padding: $container-gap-md;
  }

  // .business-program-modal__close
  &__close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $black-true;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid rgba($black-true, 0.1);
    padding: 13px;
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 2;

    @include tablet {
      right: 16px;
      top: 16px;
      height: 40px;
      width: 40px;
      padding: 10px;
    }

    @include mobile {
      right: 8px;
      top: 8px;
      width: 31px;
      height: 31px;
      border: 1px solid rgba($white-true, 0.1);
      color: $white-true;
    }

    @include hover {
      color: $black-true;

      #{$b}__close-icon {
        transform: rotate(90deg);
      }
    }

    &-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: currentColor;
        }
      }
    }
  }

  // .business-program-modal__inner
  &__inner {
    position: relative;
    max-width: 100%;
    width: 1232px;
    color: $black-true;
    background-color: $white-true;
    padding: 64px;
    margin: auto;
    transition: all 0.3s ease;
    border-radius: 64px;

    @include tablet {
      padding: 64px 32px 32px 32px;
      border-radius: 42px;
    }

    @include mobile {
      position: static;
      padding: 24px;
    }
  }

  // .business-program-modal__header
  &__header {
    position: relative;
    margin-bottom: 40px;
    padding-right: 20px;
    padding-bottom: 40px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba($black-true, 0.1);
    }

    @include tablet {
      padding-right: 0;
    }

    @include mobile {
      padding-bottom: 24px;
      margin-bottom: 24px;
    }
  }

  // .business-program-modal__tags
  &__tags {
    margin-bottom: 23px;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  // .business-program-modal__title
  &__title {
    margin: 0 0 40px 0;

    @include mobile {
      margin-bottom: 28px;
    }
  }

  // .business-program-modal__line
  &__line {
    display: flex;
    flex-wrap: wrap;
    gap: 18px 40px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include mobile {
      display: block;
    }

    // .business-program-modal__line-item
    &-item {
      display: inline-flex;
      gap: 8px;
      opacity: 0.5;

      @include mobile {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    // .business-program-modal__line-icon
    &-icon {
      display: inline-flex;
      align-items: center;
      @include boxsize(20px);
    }
  }

  // .business-program-modal__partners
  &__partners {
    display: flex;
    align-items: flex-start;

    @include mobile {
      display: block;
    }

    // .business-program-modal__partners-caption
    &-caption {
      white-space: nowrap;
      margin-right: 8px;

      @include mobile {
        white-space: normal;
        margin-right: 0;
      }
    }

    // .business-program-modal__partners-name
    &-name {
      display: inline-block;
      color: $bg-brand;
    }

    // .business-program-modal__partners-item
    &-item {
      @include mobile {
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }

  // .business-program-modal__iframe
  &__iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 1920/1080;
  }

  // .business-program-modal__persons
  &__persons {

    // .business-program-modal__persons-block
    &-block {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    // .business-program-modal__persons-title
    &-title {
      margin: 0 0 32px 0;

      @include tablet {
        margin-bottom: 25px;
      }
    }

    // .business-program-modal__persons-item
    &-item {
      &:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba($black-true, 0.1);
      }
    }
  }

  // .business-program-modal__tabs
  &__tabs {

    // .business-program-modal__tabs-description
    &-description {
      @include mobile {
        font-size: 18px;
      }
    }
  }
}
</style>
