import Vue from 'vue'
import Vuex from 'vuex'
import modal from '@/store/components/modal'
import program from '@/store/api/program'
import loading from '@/store/components/loading'
import sputnikHeader from '@/store/components/sputnik/sputnik-header'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    modal,
    program,
    loading,
    sputnikHeader
  }
})
