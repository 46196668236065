<template>
  <div class="business-participants">
    <div class="business-participants__inner">
      <div
        v-for="(col, index) in items"
        :key="`business-participants__col-${index}`"
        class="business-participants__col"
      >
        <div class="business-participants__title title-md tt-uppercase" v-html="col.group" />
        <div class="business-participants__items">
          <div
            v-for="item in col.items"
            :key="`business-participants__item-${item.id}`"
            class="business-participants__item"
          >
            <CustomCheckbox @input="checked => changeCheckbox(checked, item.id)" :value="isChecked(item.id)">
              <span class="business-participants__item-name fw-500">{{ item.name }}</span>
            </CustomCheckbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    notFoundCaption: {
      type: String,
      default: ''
    },
    filter: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    changeCheckbox(checkbox, id) {
      this.$emit('change', checkbox, id)
    },

    isChecked(id) {
      return this.filter.includes(id)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-participants';

#{$b} {

  // .business-participants__inner
  &__inner {
    column-count: 3;
    column-gap: 44px;

    @include low-desktop {
      column-gap: 32px;
    }
    @include tablet {
      column-count: 2;
    }
    @include mobile {
      column-count: unset;
      display: block;
    }
  }

  // .business-participants__title
  &__title {
    margin-bottom: 19px;
  }

  // .business-participants__col
  &__col {
    break-inside: avoid-column;
    padding: 20px 0;

    @include mobile {
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    // .business-participants__col--is-hidden
    &--is-hidden {
      display: none;
    }
  }

  // .business-participants__item
  &__item {
    &:not(:last-child) {
      margin-bottom: 13px;
    }

    // .business-participants__item--is-hidden
    &--is-hidden {
      display: none;
    }
  }
}
</style>
