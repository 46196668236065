import { getQuery, hasQueryInUrl } from '@/helpers/queryHelper.js'

export const regionFromOptions = (item, list) => {
  // сверка по свойству name для определения есть ли такой регион в списке для выбора
  // проверка по name, а не по id, потому что есть два разных ИБ - регионы и регионы мероприятий, в которых один и тот же регион создан отдельными эл-ми
  const region = item && list && list?.length > 0 && item?.name
    ? item
    : null

  const filteredRegion = region
    ? list.find(v => v.name === region.name)
    : null

  return filteredRegion?.id || ''
}

export const hrefWithRegion = (url) => {
  const separator = hasQueryInUrl(url) ? '&' : '?'
  const regionValue = getQuery({ name: 'region' })
  const query = regionValue ? `${separator}region=${regionValue}` : ''

  return `${url}${query}`
}
