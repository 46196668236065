<template>
  <section class="u-actual-news">
    <div class="u-g-container">
      <div class="u-actual-news__inner">
        <div class="u-actual-news__header">
          <component
            :is="getTitleTag"
            class="u-actual-news__title tt-uppercase"
            :class="{ 'u-actual-news__title--is-link': titleLink }"
            :href="titleLink"
          >
            <span v-html="title" />
          </component>
        </div>
        <div class="u-actual-news__body">
          <UActualNewsList
            class="u-actual-news__news-list"
            :newsList="initItems"
            :indexLarge="indexLarge"
            withoutAnonce
          />
        </div>
        <div class="u-actual-news__footer">
          <CustomButton
            class="u-actual-news__btn-news"
            tag="a"
            :href="btnLink"
            theme="primary"
            size="md"
          >
            {{ btnText }}
          </CustomButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    initItems: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    indexLarge: {
      type: Number,
      default: 0
    },
    btnText: {
      type: String,
      default: ''
    },
    btnLink: {
      type: String,
      default: '/'
    },
    titleLink: {
      type: String,
      default: ''
    },
  },

  computed: {
    getTitleTag() {
      return this.titleLink ? 'a' : 'h2'
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes';

$b: '.u-actual-news';

#{$b} {
  position: relative;
  z-index: 2;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @include mobile {
      display: block;
      margin-bottom: 24px;
    }
  }

  &__title {
    font-family: $font-family-main-head;
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    color: $black-true;
    margin: 0;

    @include tablet {
      font-size: 48px;
    }

    &--is-link {
      cursor: pointer;
      text-decoration: none;
      transition: $transtion-default;

      &:hover {
        color: $color-base-origin;
      }
    }
  }

  &__body {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  &__footer {
    @include tablet {
      text-align: center;
    }
  }
}
</style>
