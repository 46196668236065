<template>
  <div
    v-if="showBtn"
    class="scroll-top-btn"
    :class="{ 'scroll-top-btn--is-fixed' : isFixed }"
    @click="scrollTop"
  />
</template>

<script>
export default {
  data() {
    return {
      showBtn: false,
      isFixed: true,
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollPosition() {
      const footerNode = document.querySelector('#footer')

      if (footerNode.getBoundingClientRect().top < window.innerHeight && this.isFixed) {
        this.isFixed = false
      }
      if (footerNode.getBoundingClientRect().top > window.innerHeight && !this.isFixed) {
        this.isFixed = true
      }

      this.showBtn = window.pageYOffset > 200 ? true : false
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scrollPosition);
    this.$root.$on('setVisibleStatus', status => {
      this.showBtn = status
    })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollPosition);
  },
}
</script>

<style lang="scss">
@import '@/scss/base/includes';

$b: ".scroll-top-btn";

#{$b} {
  position: absolute;
  cursor: pointer;
  background-color: $white-true;
  box-shadow: 0 16px 48px rgba($black-true, 0.2);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 2L5 13' stroke='%23184FFF' stroke-width='1.5'/%3E%3Cpath d='M1 6L5 2L9 6' stroke='%23184FFF' stroke-width='1.5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  right: 12px;
  bottom: 32px;
  z-index: 3;

  @include mobile {
    width: 36px;
    height: 36px;
  }

  &--is-fixed {
    position: fixed;
  }
}
</style>
