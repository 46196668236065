<template>
  <div class="dropdown-links" :class="{ 'dropdown-links--is-opened': isOpened }">
    <div class="dropdown-links__caption u-link u-link--light" @click="toggle">
      <div class="dropdown-links__caption-text">
        {{ caption }}
      </div>
      <inline-svg class="dropdown-links__caption-icon" src="triangle-down" />
    </div>
    <div class="dropdown-links__inner" ref="dropdownInner" :style="dropdownInnerStyle">
      <div class="dropdown-links__inner-header">
        <div class="dropdown-links__tabs">
          <div
            v-for="tab in links"
            :key="`dropdown-links__tab-${tab.year}`"
            class="dropdown-links__tab title-md"
            :class="{ 'dropdown-links__tab--is-active': isActiveTab(tab.year) }"
            @click="setTab(tab.year)"
          >
            {{ tab.year }}
          </div>
        </div>
      </div>
      <div class="dropdown-links__inner-body">
        <div
          v-for="(link, i) in currentLinks"
          :key="`dropdown-links__link-${i}-${currentTabId}-${link.url}`"
          class="dropdown-links__item"
        >
          <a
            :href="link.url"
            :target="link.target"
            class="dropdown-links__link title-md"
            @click="close"
          >
            {{ link.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isOpened: false,
      currentTabId: '',
      maxHeight: 0
    }
  },
  computed: {
    currentLinks() {
      return this.currentTabId ? this.links.find(item => item.year === this.currentTabId)?.items : []
    },
    dropdownInnerStyle() {
      return {
        maxHeight: this.isOpened ? `${this.maxHeight}px` : '0'
      }
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    toggle() {
      const isOpened = !this.isOpened

      if (isOpened) {
        this.updateMaxHeight()
      }

      this.isOpened = isOpened
    },
    updateMaxHeight() {
      this.$nextTick().then(() => {
        this.maxHeight = this.$refs.dropdownInner.scrollHeight
      })
    },
    close() {
      this.isOpened = false
    },
    setTab(tab) {
      this.currentTabId = tab
      this.updateMaxHeight()
    },
    initTab() {
      if (!this.links.length || !this.links[0].year) {
        return
      }
      this.setTab(this.links[0].year)
    },
    isActiveTab(tab) {
      return this.currentTabId === tab
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.dropdown-links';

#{$b} {
  * {
    line-height: 1;

    @include mobile {
      line-height: 1.3;
    }
  }
  transition: all 0.3s ease;

  // .dropdown-links__caption
  &__caption {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: 25px;
    cursor: pointer;
    text-decoration: none;

    // .dropdown-links__caption-icon
    &-icon {
      position: absolute;
      right: 0;
      width: 14px;
      height: 14px;
      top: 50%;
      display: flex;
      transform: translateY(-50%);

      svg {
        max-width: 100%;
        max-height: 100%;
        path {
          fill: currentColor;
        }
      }

      #{$b}--is-opened & {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  // .dropdown-links__inner
  &__inner {
    max-height: 0;

    @include mobile {
      padding-top: 20px;
    }

    transition: all 0.3s ease;
    overflow: hidden;

    @include removeScrollBar();

    #{$b}:not(#{$b}--is-opened) & {
      pointer-events: none;
      touch-action: none;
      opacity: 0;
    }

    // .dropdown-links__inner-body
    &-body {
      padding: 24px 0;
    }
  }

  // .dropdown-links__item
  &__item {
    &:not(:last-child) {
      margin-bottom: 5px;

      @include mobile {
        margin-bottom: 8px;
      }
    }
  }

  // .dropdown-links__link
  &__link {
    position: relative;
    color: $white-true;
    text-transform: uppercase;
    text-decoration: none;
    animation: fade 0.5s ease;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: $white-true;
      opacity: 0;
      transition: all 0.3s ease;
    }

    @include hover {
      color: $white-true;

      &::before {
        opacity: 1;
      }
    }
  }

  // .dropdown-links__tabs
  &__tabs {
    display: flex;
    align-items: center;
  }

  // .dropdown-links__tab
  &__tab {
    position: relative;
    color: $white-true;
    font-family: $font-family-main-head;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: $white-true;
      transition: all 0.3s ease;
    }

    &:not(#{$b}__tab--is-active) {
      opacity: 0.5;

      &::before {
        opacity: 0;
      }
    }

    @include hover {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-right: 40px;

      @include mobile {
        margin-right: 24px;
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
