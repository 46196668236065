<template>
  <div class="business-program-list">
    <BusinessProgramModal :isVisible="isExistsCurrentProgram" @close="onCloseProgram" :lang="lang" :program="currentProgram" />
    <div class="business-program-list__list">
      <BusinessProgramItem
        v-for="(item, i) in getItems"
        :key="`business-program-list__item-${i}`"
        class="business-program-list__item"
        :program="item"
        @click="onClickProgram"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: ''
    },
    currentProgram: {
      type: [Object, null],
      default: null
    }
  },

  computed: {
    isExistsCurrentProgram() {
      return !!(this.currentProgram && Object.keys(this.currentProgram).length)
    },

    getItems() {
      return this.items.map(item => {
        const timeStr = item.data?.time ? this.getFormatStr(item.data.time.from, item.data.time.to) : ''
        const dateStr = item.data?.date ? this.getFormatStr(item.data.date.start, item.data.date.end) : ''

        return {
          ...item,
          timeStr,
          dateStr
        }
      })
    }
  },

  methods: {
    getFormatStr(initialFrom, initialTo) {
      const from = initialFrom || ''
      const to = initialTo || ''

      const separator = from && to ? ' — ' : ''

      return `${from}${separator}${to}`
    },

    onClickProgram({ id }) {
      this.$emit('openProgram', id)
    },

    onCloseProgram() {
      this.$emit('closeProgram')
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-program-list';

#{$b} {
  // .business-program-list__item
  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;

      @include mobile {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
