<template>
  <div v-if="IS_OPENED_MENU" class="sputnik-header-burger-menu">
    <div class="u-container sputnik-header-burger-menu__container">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('sputnikHeader', ['IS_OPENED_MENU'])
  },
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.sputnik-header-burger-menu';

#{$b} {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: calc(var(--header-height) + 20px);
  padding-bottom: 64px;
  background-color: $bg-brand;

  @include mobile {
    padding-top: var(--header-height);
  }

  @include mobile {
    padding-bottom: 32px;
  }

  // .sputnik-header-burger-menu__container
  &__container {
    height: 100%;
  }

  // .sputnik-header-burger-menu__inner
  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;

    @include removeScrollBar();
  }

  // .sputnik-header-burger-menu__nav
  &__nav {
    @include gridcols(2, 64px);
    row-gap: 32px;
    max-width: 950px;

    @include mobile {
      @include gridcols(1, 24px);
    }

    // .sputnik-header-burger-menu__nav-link
    &-link {
      color: $white-true;
      text-decoration: none;
      transition: all 0.3s ease;

      @include hover {
        opacity: 0.7;
      }
    }
  }

  // .sputnik-header-burger-menu__footer
  &__footer {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid $white-true;

    @include mobile {
      margin-top: 24px;
      padding-top: 24px;
    }
  }

  // .sputnik-header-burger-menu__contacts
  &__contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;


    @include mobile {
      flex-direction: column;
    }

    // .sputnik-header-burger-menu__contacts-link
    &-link {
      color: $white-true;
      text-decoration: none;
      transition: all 0.3s ease;

      @include hover {
        opacity: 0.7;
      }

      @include mobile {
        font-size: 16px;
      }
    }
  }
}
</style>
